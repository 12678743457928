import { brushDataTypes } from "./constants";
import {
  celsiusToFahrenheit,
  getDistanceUnitText,
  getTemperatureUnitText,
} from "./measurementUnits";

export function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function isBetweenValues(
  value: number | string,
  min: number,
  max: number
) {
  if (value == null || value === undefined) {
    return false;
  }

  let parsedVal = parseFloat(value.toString().replace(",", "."));
  min = parseFloat(min.toString().replace(",", "."));
  max = parseFloat(max.toString().replace(",", "."));

  if (isNaN(parsedVal)) {
    return false;
  }

  return parsedVal >= min && parsedVal <= max;
}

export const getTypeSuffix = (type: string, currentUser: any) => {
  switch (type) {
    case brushDataTypes.temp.value:
    case brushDataTypes.ambientTemp.value:
      return getTemperatureUnitText(currentUser?.userSettings.temperatureUnit!);
    case brushDataTypes.wear.value:
    case brushDataTypes.ambientHumidity.value:
      return "%";
    case brushDataTypes.currentFreq.value:
      return "Hz";
    case brushDataTypes.currentAmpl.value:
      return "A";
    case brushDataTypes.wearMm.value:
      return getDistanceUnitText(currentUser?.userSettings.distanceUnit!);
    default:
      return "";
  }
};
export function fixTempValues(value: number) {
  let tempTemp = value;
  if (tempTemp !== null) {
    tempTemp = parseFloat(tempTemp.toString().replace(",", "."));
  }
  return tempTemp;
}
