import { createContext } from "react";
import { IUser } from "../models/IUser";
import { ISelectOption } from "../models/SelectOption";
export const apiBaseUrl = "https://ibrushapi.azurewebsites.net/";
// export const apiBaseUrl = "https://ibrushapitest.azurewebsites.net/";
// export const apiBaseUrl = "http://localhost:5159/";

export const signalIRUrl =
  "https://i-brush-realtime-updates-prd.azurewebsites.net/api/negotiate";
// export const signalIRUrl =
//   "https://i-brush-realtime-updates.azurewebsites.net/api/negotiate";
// export const signalIRUrl =
// "http://localhost:7148/api/negotiate";

export const brushDataTypes = {
  wear: { value: "wear", label: "Brush Wear (%)", dbKey: "wearPercent" },
  wearMm: { value: "wear ", label: "Brush Wear (mm)", dbKey: "totalLengthMm" },
  temp: { value: "temp", label: "Brush Temperature", dbKey: "temp" },
  ambientTemp: {
    value: "ambientTemp",
    label: "Enclosure Temperature",
    dbKey: "temp",
  },
  ambientHumidity: {
    value: "humidity",
    label: "Enclosure Humidity (%)",
    dbKey: "humidity",
  },
  currentFreq: {
    value: "freq",
    label: "Frequency (Hz)",
    dbKey: "freq",
  },
  currentAmpl: {
    value: "ampl",
    label: "Amplitude (A)",
    dbKey: "ampl",
  },
};

export const deviceParameters = {
  activateTempAlarmDegreesCelsius: 120,
  activateTempWarningDegreesCelsius: 100,
  activateWearAlarmPercent: 90,
  activateWearAlarmDays: 302,
  activateWearWarningPercent: 80,
  activateWearWarningDays: 62,
  activateDefaultedAlarmAfterDays: 2,
  activateAmbientTempAlarmDegreesCelsius: 120,
  activateAmbientTempWarningDegreesCelsius: 100,
  deviceUploadIntervalHours: 1,
  deviceParameterIntervalHours: 2,
  transmitterMeasurementInterval: 20,
  transmitterCompleteMeasurementInterval: 119,
  transmitterInitialMeasurements: 51,
  transmitterTempFactor: 12,
  measurementIntervalEnclosure: 6,
  activateCurrentAlarmAmp: 140,
  activateCurrentWarningAmp: 120,
  transmitterNodeAlarm: 25,
  transmitterBatteryAlarm: 2,
  enclosureMeasurementInterval: 6,
  receivedAt: "0001-01-01T00:00:00",
  mailTimeOut: 0,
};

export const eventTypes = {
  current: "Current",
  temperature: "Temperature",
  weardays: "Weardays",
  wearpercent: "WearPercent",
  wearRate: "WearRate",
  node: "Node",
  battery: "Battery",
  currentdensity: "Currentdensity",
};
export interface IContextData {
  currentUser: IUser | null;
}

export const contextDefaultValue: IContextData = {
  currentUser: null,
};

export const IbrushContext = createContext<IContextData>(contextDefaultValue);
